import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import TOC from "components/toc";
import SaleLoadable from "components/saleLoadable";
import Socials from "components/socials";
import SmallBox from "components/smallBox";
import AccentBox from "components/accentBox";
import BasicTable from "components/basicTable";
import { Link, graphql } from "gatsby";
import Card from "components/card";
import PassMarkSearch from 'components/passMarkSearch';
import PassMark from 'components/cpu/passmark';
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "used-pc"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/others/usedpc.png"}) { ...eyecatchImg },
    ccpuhikaku: file(relativePath: { eq: "cover/cpu/cpu-hikaku.png"}) { ...eyecatchImg },
    cgrabohikaku: file(relativePath: { eq: "cover/gpu/grabo-hikaku.png"}) { ...eyecatchImg },
    cusedshop: file(relativePath: { eq: "cover/rank/usedshop.png"}) { ...eyecatchImg },
    amazon: file(relativePath: { eq: "where/amazon.png"}) { ...normalImg },
    rycycleshop: file(relativePath: { eq: "where/rycycleshop.png"}) { ...normalImg },
    freemarket: file(relativePath: { eq: "where/freemarket.png"}) { ...normalImg },
    junk_world: file(relativePath: { eq: "where/junk_world.png"}) { ...normalImg },
    pcnext: file(relativePath: { eq: "where/pcnext.png"}) { ...normalImg },
    qualit: file(relativePath: { eq: "where/qualit.png"}) { ...normalImg },
    akiba: file(relativePath: { eq: "where/akiba.jpg"}) { ...normalImg },
    pc_ichiba: file(relativePath: { eq: "where/pc_ichiba.png"}) { ...normalImg },
    usedpcshopmap: file(relativePath: { eq: "where/usedpcshopmap.png"}) { ...normalImg },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`中古パソコンはどこで買うべきかのココだけの話`}</h1>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="中古パソコンはどこで買うべきか" mdxType="Image" />
    <p>{`もともと私は中古パソコンは反対派であった。数年前のパソコンなど性能面で使い物にならないからである。ところが半導体の性能が１年半で２倍になるというムーアの法則は崩れ、数年前のパソコンと現在のパソコンとの性能差は縮まり、`}<strong parentName="p"><em parentName="strong">{`最近では中古パソコンを購入しても快適に作業が行なえるようになってきた`}</em></strong>{`。`}</p>
    <p>{`実際インターネットの閲覧や軽い動画編集で数年前の中古ノートパソコンが力不足ということは無く、更に円安などによる物価上昇の影響で新品のパソコン価格が上がってきたという事実もあり、`}<strong parentName="p"><em parentName="strong">{`今後中古パソコンの市場は急拡大するとの見通し`}</em></strong>{`も出ている。`}</p>
    <p>{`ところがいざ中古パソコンを購入するにしても、どこで買うべきかという疑問に率直に応えられているサイトは少ない。そこで`}<strong parentName="p"><em parentName="strong">{`元家電量販店の販売員、現大手電機メーカー社員である管理人が忖度なしで答えていく`}</em></strong>{`。`}</p>
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "どこで買うのが一番お買い得か？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%81%A9%E3%81%93%E3%81%A7%E8%B2%B7%E3%81%86%E3%81%AE%E3%81%8C%E4%B8%80%E7%95%AA%E3%81%8A%E8%B2%B7%E3%81%84%E5%BE%97%E3%81%8B%EF%BC%9F",
        "aria-label": "どこで買うのが一番お買い得か？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`どこで買うのが一番お買い得か？`}</h2>
    <p>{`結局どこで購入するのが正解かという答えを知る前に、まずは選択肢を俯瞰するのがよいと思われる。品質と価格、保証など様々な観点で話していきたい。`}</p>
    <h3 {...{
      "id": "フリマアプリメルカリ・ラクマ---意外と高くて使いにくい",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%95%E3%83%AA%E3%83%9E%E3%82%A2%E3%83%97%E3%83%AA%E3%83%A1%E3%83%AB%E3%82%AB%E3%83%AA%E3%83%BB%E3%83%A9%E3%82%AF%E3%83%9E---%E6%84%8F%E5%A4%96%E3%81%A8%E9%AB%98%E3%81%8F%E3%81%A6%E4%BD%BF%E3%81%84%E3%81%AB%E3%81%8F%E3%81%84",
        "aria-label": "フリマアプリメルカリ・ラクマ   意外と高くて使いにくい permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`フリマアプリ(メルカリ・ラクマ) - 意外と高くて使いにくい`}</h3>
    <Image {...props} name="freemarket" alt="フリマアプリ" mdxType="Image" />
    <p><strong parentName="p"><em parentName="strong">{`リユースショップを通さない個人取引のため最も安く購入できる、と期待すると大外れ`}</em></strong>{`であり、それほど安い製品が出ていないのがフリマアプリである。`}</p>
    <p>{`メルカリは子供用の服やおもちゃ、指輪などは恐ろしく安い価格で出品されるが、それと同じことをパソコンに期待するのはナンセンスである。`}</p>
    <p>{`出品者層は中古価格帯を良く理解しており、一般的な中古ショップと比べたらやや安い程度の価格か、あわよくば同等かそれ以上の価格帯で出品していることが多い。そもそも自前でオンラインショップを開設できないような弱小業者も多い。`}</p>
    <p>{`品質にはムラがあり、保証無し、適当な梱包、認証に問題のあるMicrosoft Officeなど`}<strong parentName="p"><em parentName="strong">{`問題のオンパレードであり、相当な目利き力が無い限り手を出すのは時間と金の無駄である`}</em></strong>{`。`}</p>
    <p>{`逆に言うと多少価格を落とせば保証も無しに初心者が買っていくため`}<strong parentName="p"><em parentName="strong">{`売り手にとっては魅力的なマーケット`}</em></strong>{`である。機会があれば出品してみよう。`}</p>
    <p>{`ただ１点、ゲーミングPCに関しては中古ショップでの出回りが少なく、買い手のリテラシーが高い場合に限りフリマアプリはお得な選択肢となるだろう。`}</p>
    <h3 {...{
      "id": "ECモール---蔓延する詐欺まがいの手口",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#EC%E3%83%A2%E3%83%BC%E3%83%AB---%E8%94%93%E5%BB%B6%E3%81%99%E3%82%8B%E8%A9%90%E6%AC%BA%E3%81%BE%E3%81%8C%E3%81%84%E3%81%AE%E6%89%8B%E5%8F%A3",
        "aria-label": "ECモール   蔓延する詐欺まがいの手口 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ECモール - 蔓延する詐欺まがいの手口`}</h3>
    <Image {...props} name="amazon" alt="ECモール" mdxType="Image" />
    <p>{`ECモールのパソコンショップ市場はフリマアプリと比べると管理されているが、価格が高く、品質も同様に玉石混交である。`}</p>
    <p>{`よくある詐欺まがいの手口として、もともとメルカリでパソコンを売っていた個人が、数十万円で評価が高いAmazonのセラーアカウントを購入して引き継ぐというものである。`}</p>
    <p>{`この場合Amazonというショッピングモールの信頼性を金で買っているだけなので、内情はメルカリと変わらないのだが、何となく購入者にとって安心感があるため高値で売れる。`}</p>
    <p>{`優良な中古業者が売っていることもあるが、`}<strong parentName="p"><em parentName="strong">{`フリマアプリと同様目利きが非常に難しいので、基本的には利用しない方が良い`}</em></strong>{`だろう。保証面でも不安が残る。ちなみにユーザのリテラシーの関係かAmazonと比べると楽天の方が割高な印象。`}</p>
    <h3 {...{
      "id": "リサイクル店---簡素なチェックで欠陥品を掴む可能性大",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%AA%E3%82%B5%E3%82%A4%E3%82%AF%E3%83%AB%E5%BA%97---%E7%B0%A1%E7%B4%A0%E3%81%AA%E3%83%81%E3%82%A7%E3%83%83%E3%82%AF%E3%81%A7%E6%AC%A0%E9%99%A5%E5%93%81%E3%82%92%E6%8E%B4%E3%82%80%E5%8F%AF%E8%83%BD%E6%80%A7%E5%A4%A7",
        "aria-label": "リサイクル店   簡素なチェックで欠陥品を掴む可能性大 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`リサイクル店 - 簡素なチェックで欠陥品を掴む可能性大`}</h3>
    <Image {...props} name="rycycleshop" alt="リサイクルショップ" mdxType="Image" />
    <p>{`ブックオフやハードオフなどのリサイクルショップでもパソコンは売っているが、これは`}<strong parentName="p"><em parentName="strong">{`フリマアプリ以上におすすめできない`}</em></strong>{`。`}</p>
    <p>{`ハードオフにパソコンを売りに行けばわかるのだが、10分程度の簡単な動作チェックで買取が決まってしまう。買取に専門スタッフが少ないためオペレーションを簡素化しているのである。`}</p>
    <p>{`更に買取価格も他店よりも安い。実体験としてはパソコン工房で3万5000円の査定が出たものをハードオフに持っていくと2万1000円であった。`}</p>
    <p>{`この結果当然店頭に並ぶ中古パソコンは、`}<strong parentName="p"><em parentName="strong">{`他のパソコンショップでは取り扱いができない代物が大量に混ざる`}</em></strong>{`ことになり、粗悪品を掴む可能性が上がってしまうのである。`}</p>
    <p>{`基本的に安かろう悪かろうで、当たりはずれも大きいため避けるのが無難である。`}</p>
    <h3 {...{
      "id": "家電量販店系---中古PCにリベートも無く割高",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%AE%B6%E9%9B%BB%E9%87%8F%E8%B2%A9%E5%BA%97%E7%B3%BB---%E4%B8%AD%E5%8F%A4PC%E3%81%AB%E3%83%AA%E3%83%99%E3%83%BC%E3%83%88%E3%82%82%E7%84%A1%E3%81%8F%E5%89%B2%E9%AB%98",
        "aria-label": "家電量販店系   中古PCにリベートも無く割高 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`家電量販店系 - 中古PCにリベートも無く割高`}</h3>
    <Image {...props} name="akiba" alt="量販店" mdxType="Image" />
    <p>{`ソフマップやヤマダ電機などの家電量販店でも中古パソコンは扱われているが、正直安くはない。`}</p>
    <p>{`新品の場合はメーカーからのリベートや、大量販売による戦略的価格設定が取れる、あるいは`}<Link to="/where/" mdxType="Link">{`【元販売員が語る】パソコンはどこで買うべきかのココだけの話`}</Link>{`でも述べているが、メーカーとの力関係により価格競争力はネットと比べて必ずしも低いわけではない。`}</p>
    <p>{`ところが`}<strong parentName="p"><em parentName="strong">{`中古に関してはメーカーとのつながりによる利益がない`}</em></strong>{`ため、単純に駅前一等地に構える土地の無駄という１点に尽き、中古に対する効率的なオペレーションも専門店に比べると弱い。`}</p>
    <p>{`それゆえ価格競争力は低く、最悪新品とほとんど値段が変わらなかったりする。`}<strong parentName="p"><em parentName="strong">{`ふと通りかかった時にドンピシャな中古が見つからない限りは時間の無駄`}</em></strong>{`であろう。`}</p>
    <h3 {...{
      "id": "中古パソコンショップ---高い専門性による安定した品質",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E4%B8%AD%E5%8F%A4%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%82%B7%E3%83%A7%E3%83%83%E3%83%97---%E9%AB%98%E3%81%84%E5%B0%82%E9%96%80%E6%80%A7%E3%81%AB%E3%82%88%E3%82%8B%E5%AE%89%E5%AE%9A%E3%81%97%E3%81%9F%E5%93%81%E8%B3%AA",
        "aria-label": "中古パソコンショップ   高い専門性による安定した品質 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`中古パソコンショップ - 高い専門性による安定した品質`}</h3>
    <p>{`唯一品質の問題をクリアしているのが中古パソコンショップである。専門的な人材が揃っており、買取時にもプロセスに従い入念なチェックがされるため、`}<strong parentName="p"><em parentName="strong">{`品質の悪いパソコンを購入する機会は少ない`}</em></strong>{`。`}</p>
    <p>{`そして中古パソコンショップの中でも量販店などと比べ、中古パソコンのみを専門的に扱っている会社の方がオペレーションがしっかりしており、価格的にも抑えられている傾向が強い。`}</p>
    <p>{`すなわち`}<strong parentName="p"><em parentName="strong">{`中古のみに特化することで効率を上げ、再販価格を抑えている`}</em></strong>{`のである。`}</p>
    <h3 {...{
      "id": "まとめ---中古パソコンを買うならどこか？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%81%BE%E3%81%A8%E3%82%81---%E4%B8%AD%E5%8F%A4%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%82%92%E8%B2%B7%E3%81%86%E3%81%AA%E3%82%89%E3%81%A9%E3%81%93%E3%81%8B%EF%BC%9F",
        "aria-label": "まとめ   中古パソコンを買うならどこか？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`まとめ - 中古パソコンを買うならどこか？`}</h3>
    <Image {...props} name="usedpcshopmap" alt="中古パソコンをどこで買うかのカオスマップ" mdxType="Image" />
    <p>{`以上をまとめ、品質とコストパフォーマンスの観点から情報を整理すると上図のようになる。`}</p>
    <p>{`確かにラクマやメルカリで優良な出品者を探し、ライバルに負けないように毎日価格をウォッチし、保証無しを考慮しても割安なものを探す、ということもできなくはないが、`}<strong parentName="p"><em parentName="strong">{`効率が良い探し方は専門的な中古パソコンショップから探すことである`}</em></strong>{`。`}</p>
    <p>{`もっとも、ひとことで中古パソコンショップといっても数多く存在するため、図の右上にロゴにも載っているような買うべきおすすめの中古パソコンショップを次に紹介する。`}</p>
    <p>{`ただ、その前に失敗を防ぐための注意としてCPUの選び方を掲載しておく。`}</p>
    <h2 {...{
      "id": "中古パソコン購入前にCPU性能をチェック",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E4%B8%AD%E5%8F%A4%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E8%B3%BC%E5%85%A5%E5%89%8D%E3%81%ABCPU%E6%80%A7%E8%83%BD%E3%82%92%E3%83%81%E3%82%A7%E3%83%83%E3%82%AF",
        "aria-label": "中古パソコン購入前にCPU性能をチェック permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`中古パソコン購入前にCPU性能をチェック`}</h2>
    <p>{`CPUはパソコンの処理速度の根幹をなす重要なパーツであるが、型番を見ただけでは性能が分からないため、`}<strong parentName="p"><em parentName="strong">{`PassMarkというCPUの総合的な性能を指数化したベンチマークテストの結果を使うのがおすすめ`}</em></strong>{`である。`}</p>
    <p>{`スコアの目安とチェックツール(`}<em parentName="p">{`PASSMARK SEARCH`}</em>{`)を下に用意しているので、中古パソコンの候補が見つかれば、スペックからCPUの型番情報を入力して性能をチェックして欲しい。`}</p>
    <PassMark mdxType="PassMark" />
    <PassMarkSearch mdxType="PassMarkSearch" />
    <h2 {...{
      "id": "中古パソコンのおすすめショップ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E4%B8%AD%E5%8F%A4%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%AE%E3%81%8A%E3%81%99%E3%81%99%E3%82%81%E3%82%B7%E3%83%A7%E3%83%83%E3%83%97",
        "aria-label": "中古パソコンのおすすめショップ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`中古パソコンのおすすめショップ`}</h2>
    <p>{`それでは、中古パソコンショップの中で`}<strong parentName="p"><em parentName="strong">{`おすすめできるパソコンショップをランキング形式で５つ紹介`}</em></strong>{`する。`}</p>
    <p>{`特にランキング上位２社は価格、品質、保証面で高い水準を有しているおり、３位は品質・保証面は劣るがコスパと品揃えに秀でるため、`}<strong parentName="p"><em parentName="strong">{`まずは上位３ショップの中で探すことが効率的でかつ確実`}</em></strong>{`であろう。`}</p>
    <h3 {...{
      "id": "１位：Qualit---ビジネスクオリティの中古パソコン",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%EF%BC%91%E4%BD%8D%EF%BC%9AQualit---%E3%83%93%E3%82%B8%E3%83%8D%E3%82%B9%E3%82%AF%E3%82%AA%E3%83%AA%E3%83%86%E3%82%A3%E3%81%AE%E4%B8%AD%E5%8F%A4%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3",
        "aria-label": "１位：Qualit   ビジネスクオリティの中古パソコン permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`１位：Qualit - ビジネスクオリティの中古パソコン`}</h3>
    <Image {...props} name="qualit" alt="Qualit" mdxType="Image" />
    <BasicTable className={'center top-margin'} mdxType="BasicTable">
  <tbody>
    <tr>
      <th>コスパ</th>
      <th>品質</th>
      <th>品揃え</th>
      <th>送料</th>
      <th>保証</th>
    </tr>
    <tr>
      <td>S</td>
      <td>S</td>
      <td>B</td>
      <td>１万円以上無料</td>
      <td>12ヵ月</td>
    </tr>
  </tbody>
    </BasicTable>
    <p>{`まず最もおすすめしたいサイトがこのQualitである。他の中古パソコンと比べ圧倒的なコストパフォーマンスと品質を誇る。`}</p>
    <p>{`横河レンタ・リースという法人向けとして有名なパソコンのレンタルサービスがあり、私が勤めている会社でも利用しているのだが、`}<strong parentName="p"><em parentName="strong">{`レンタル期限を終えて返却されたパソコンを再整備して売りに出している`}</em></strong>{`のである。`}</p>
    <p>{`安値で仕入れ、高値で売るという多くの中古パソコンショップとは`}<strong parentName="p"><em parentName="strong">{`ビジネススキームで一線を画しており、これが圧倒的な価格競争力を実現`}</em></strong>{`している。`}</p>
    <p>{`更に会社として法人向けの厳しいビジネス要求に応えられるよう`}<strong parentName="p"><em parentName="strong">{`バッテリーチェックを含め高いレベルの点検・整備を行なっている`}</em></strong>{`ので品質面で非の打ちどころは無い。`}</p>
    <p>{`法人用にレンタルで使われているという事実は、個人で無理な使われ方をしたパソコンが来ないという点でも安心材料である。`}</p>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
  <a href="https://px.a8.net/svt/ejp?a8mat=3NPOM7+F0LP5E+50H8+5YJRM" target="_blank" rel="nofollow noopener">Qualit</a>
  <img border="0" width="1" height="1" src="https://www12.a8.net/0.gif?a8mat=3NPOM7+F0LP5E+50H8+5YJRM" alt="" />
    </SmallBox>
    <h3 {...{
      "id": "２位：PC-WRAP---業界最長３年保証を誇る中古PCの老舗",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%EF%BC%92%E4%BD%8D%EF%BC%9APC-WRAP---%E6%A5%AD%E7%95%8C%E6%9C%80%E9%95%B7%EF%BC%93%E5%B9%B4%E4%BF%9D%E8%A8%BC%E3%82%92%E8%AA%87%E3%82%8B%E4%B8%AD%E5%8F%A4PC%E3%81%AE%E8%80%81%E8%88%97",
        "aria-label": "２位：PC WRAP   業界最長３年保証を誇る中古PCの老舗 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`２位：PC WRAP - 業界最長３年保証を誇る中古PCの老舗`}</h3>
    <Image {...props} name="pcwrap" alt="PCWrap" mdxType="Image" />
    <BasicTable className={'center top-margin'} mdxType="BasicTable">
  <tbody>
    <tr>
      <th>コスパ</th>
      <th>品質</th>
      <th>品揃え</th>
      <th>送料</th>
      <th>保証</th>
    </tr>
    <tr>
      <td>A+</td>
      <td>A+</td>
      <td>A</td>
      <td>9999円以上無料</td>
      <td>3年</td>
    </tr>
  </tbody>
    </BasicTable>
    <p>{`東証一部上場の急成長IT企業であるShiftのグループ会社である株式会社エスエヌシーが運営するパソコンショップで、大阪を主な拠点とする。`}</p>
    <p>{`中古PC販売20年以上の老舗であり、年間６万台程度パソコンを再生するトップクラスの取引量を誇る。`}</p>
    <p>{`取引量と効率的な検品のオペレーションからくる`}<strong parentName="p"><em parentName="strong">{`コストパフォーマンスに加え、３年保証、当日発送など購入者にとっての安心材料も豊富`}</em></strong>{`である。`}</p>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
      <a href="https://px.a8.net/svt/ejp?a8mat=3NPOM7+FVK8LU+4U56+5YRHE" target="_blank" rel="nofollow noopener">PC WRAP</a>
      <img border="0" width="1" height="1" src="https://www13.a8.net/0.gif?a8mat=3NPOM7+FVK8LU+4U56+5YRHE" alt="" />
    </SmallBox>
    <h3 {...{
      "id": "３位：JUNKWORLD---激安中古パソコンの殿堂",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%EF%BC%93%E4%BD%8D%EF%BC%9AJUNKWORLD---%E6%BF%80%E5%AE%89%E4%B8%AD%E5%8F%A4%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%AE%E6%AE%BF%E5%A0%82",
        "aria-label": "３位：JUNKWORLD   激安中古パソコンの殿堂 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`３位：JUNKWORLD - 激安中古パソコンの殿堂`}</h3>
    <BasicTable className={'center top-margin'} mdxType="BasicTable">
  <tbody>
    <tr>
      <th>コスパ</th>
      <th>品質</th>
      <th>品揃え</th>
      <th>送料</th>
      <th>保証</th>
    </tr>
    <tr>
      <td>A+</td>
      <td>B</td>
      <td>S</td>
      <td>900円~</td>
      <td>1ヵ月</td>
    </tr>
  </tbody>
    </BasicTable>
    <Image {...props} name="junk_world" alt="JUNKWORLD" mdxType="Image" />
    <p>{`株式会社アールキューブというパソコン回収とデータ消去を中心とした事業を行なっている会社が運営している中古パソコンショップ。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.pc-kaishu.com/",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`パソコン回収.com`}</a>{`などを通じて、法人、個人双方から不要になったパソコンの回収を行なっており、`}<strong parentName="p"><em parentName="strong">{`個人はどんなパソコンであっても無料で引き取ってくれる`}</em></strong>{`。`}</p>
    <p>{`このサービス、あるいは買取を通じて集まったパソコンのうち再利用可能なものを再整備して発売しているサイトがJUNKWORLDである。`}</p>
    <p>{`他社が真似できないユニークな方法でパソコンを集めていおり、年間数万台の販売実績を誇り規模も大きい事から`}<strong parentName="p"><em parentName="strong">{`品揃えや価格競争力は十分に高い`}</em></strong>{`が、ショップ名の通りジャンク品が豊富で品質のバラつきには注意が必要なため、商品写真等には目を配りたい。`}</p>
    <p>{`また、万が一不具合を引き当てた場合は30日と標準的な返品保証もついてくるので活用したい。`}</p>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
      <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=888459649" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=888459649" height="1" width="1" border="0" />JUNKWORLD</a>
    </SmallBox>
    <h3 {...{
      "id": "４位：パソコン市場---安くはないが初中級者に優しいパソコン",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%EF%BC%94%E4%BD%8D%EF%BC%9A%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E5%B8%82%E5%A0%B4---%E5%AE%89%E3%81%8F%E3%81%AF%E3%81%AA%E3%81%84%E3%81%8C%E5%88%9D%E4%B8%AD%E7%B4%9A%E8%80%85%E3%81%AB%E5%84%AA%E3%81%97%E3%81%84%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3",
        "aria-label": "４位：パソコン市場   安くはないが初中級者に優しいパソコン permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`４位：パソコン市場 - 安くはないが初中級者に優しいパソコン`}</h3>
    <Image {...props} name="pc_ichiba" alt="パソコン市場" mdxType="Image" />
    <BasicTable className={'center top-margin'} mdxType="BasicTable">
  <tbody>
    <tr>
      <th>コスパ</th>
      <th>品質</th>
      <th>品揃え</th>
      <th>送料</th>
      <th>保証</th>
    </tr>
    <tr>
      <td>B</td>
      <td>B+</td>
      <td>A</td>
      <td>1430円~</td>
      <td>6ヵ月</td>
    </tr>
  </tbody>
    </BasicTable>
    <p>{`中古パソコンショップでは老舗で、`}<strong parentName="p"><em parentName="strong">{`マイクロソフトMAR（Microsoft Authorized Refurbisher）プログラムに認定された再生PC事業者`}</em></strong>{`。`}</p>
    <p>{`パソコン自体のコストパフォーマンスはそれほど高くはないが、送料のみの負担で３０日返品可能、土日サポート、東京都から鹿児島まで25店舗程度の実店舗運営などは`}<strong parentName="p"><em parentName="strong">{`パソコン初心者～中級者にとって非常に安心感が高い`}</em></strong>{`ポイントである。`}</p>
    <p>{`正規のマイクロソフトオフィスがインストールされたパソコンを手軽に使いたいという人や、あまりパソコンに自信はないがコストは抑えたい人にとって良い選択肢となるはずである。`}</p>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
      <a href="https://px.a8.net/svt/ejp?a8mat=3T0ILT+7X6QMQ+4TG6+5YRHE" target="_blank" rel="nofollow noopener">パソコン市場</a>
      <img border="0" width="1" height="1" src="https://www19.a8.net/0.gif?a8mat=3T0ILT+7X6QMQ+4TG6+5YRHE" alt="" />
    </SmallBox>
    <h3 {...{
      "id": "５位：PC-NEXT---型落ちの日系パソコンを買うなら狙い目",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%EF%BC%95%E4%BD%8D%EF%BC%9APC-NEXT---%E5%9E%8B%E8%90%BD%E3%81%A1%E3%81%AE%E6%97%A5%E7%B3%BB%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%82%92%E8%B2%B7%E3%81%86%E3%81%AA%E3%82%89%E7%8B%99%E3%81%84%E7%9B%AE",
        "aria-label": "５位：PC NEXT   型落ちの日系パソコンを買うなら狙い目 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`５位：PC NEXT - 型落ちの日系パソコンを買うなら狙い目`}</h3>
    <Image {...props} name="pcnext" alt="PC next" mdxType="Image" />
    <BasicTable className={'center top-margin'} mdxType="BasicTable">
  <tbody>
    <tr>
      <th>コスパ</th>
      <th>品質</th>
      <th>品揃え</th>
      <th>送料</th>
      <th>保証</th>
    </tr>
    <tr>
      <td>B</td>
      <td>S</td>
      <td>B+</td>
      <td>無料</td>
      <td>12ヵ月</td>
    </tr>
  </tbody>
    </BasicTable>
    <p>{`コンプライアンスが厳しい関西電力の子会社が運営する中古パソコンショップ。故障率が高いHDDを新品のSSDに交換して販売する、１年保証、販売商品に一定のスペック基準を設けるなど`}<strong parentName="p"><em parentName="strong">{`使用者が安心して中古パソコンを使えることに注力している`}</em></strong>{`ことが特徴である。`}</p>
    <p>{`ただ、当然価格は高くなるわけであり、正直DellやLenovoのPCをここで購入するぐらいならば新品を購入することをおすすめしたい。`}</p>
    <p>{`しかしながらNEC、Dynabook、Vaio、Let's Noteなど`}<strong parentName="p"><em parentName="strong">{`日系ブランドの製品は正規価格と比べ割引率が高く、しっかり整備されていることを考えると狙い目`}</em></strong>{`である。`}</p>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
      <a href="https://px.a8.net/svt/ejp?a8mat=3Z4ON3+G1IKNM+4X3U+5YRHE" target="_blank" rel="nofollow noopener">PC next</a>
      <img border="0" width="1" height="1" src="https://www14.a8.net/0.gif?a8mat=3Z4ON3+G1IKNM+4X3U+5YRHE" alt="" />
    </SmallBox>
    <h3 {...{
      "id": "その他の中古パソコンショップ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%81%9D%E3%81%AE%E4%BB%96%E3%81%AE%E4%B8%AD%E5%8F%A4%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%82%B7%E3%83%A7%E3%83%83%E3%83%97",
        "aria-label": "その他の中古パソコンショップ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`その他の中古パソコンショップ`}</h3>
    <p>{`その他CPU性能の簡単検索ツールや詳しい説明とともに、下記にて他の中古パソコンおすすめショップも紹介しているので、より多くの選択肢の中から選びたいという場合には確認して欲しい。`}</p>
    <Card {...props} title="中古パソコンおすすめショップランキング" description="品質とコスパに優れた中古パソコンのおすすめショップをランキング形式で紹介します。" image="cusedshop" path="/used-shop/" mdxType="Card" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      